import React, { useState } from "react"
import { graphql } from "gatsby"
import "queries/featuredTemplateQuery"
import Layout from "components/Layout/Layout"
import FeaturedLayout from "components/FeaturedLayout/FeaturedLayout"
import LoginPage from "components/shared/LoginPage"

const FeaturedTemplate = (props) => {
    const [grantAccess, setGrantAccess] = useState(false)
    const data = props.data.wpPage
    const title = data.seo.title || data.title
    const googleAds = data.acfGoogleAds
    const googleAdsSidebar = data.template.acfGoogleAdsSidebar
    const { passwordProtected, password, footerBellyband } = data.acfPageSettings

    const handleLogin = (userPassword) => {
        if (userPassword === password) setGrantAccess(true)
    }
    
    // basic login to protect a general user from viewing page, not secure!
    if (passwordProtected && !grantAccess) {
        return <LoginPage handleLogin={handleLogin} />
    }

    return (
        <Layout 
            title={title} 
            description={data.seo.metaDesc} 
            path={data.uri} 
            googleAds={googleAds} 
            seo={data.seo} 
            footerBellyband={footerBellyband}>
            <FeaturedLayout data={data} googleAdsSidebar={googleAdsSidebar} />
        </Layout>
    )
}

export const pageQuery = graphql`
query($id: String!) {
    wpPage(id: {eq: $id}) {
        title
        uri
        seo {
            title
            metaDesc
        }
        acfGoogleAds {
            bellybandName
        }
        acfPageSettings {
            passwordProtected
            password
            footerBellyband {
                desktopImage {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(width: 970, quality: 95, placeholder: NONE, layout: CONSTRAINED)
                        }
                    }
                }
                tabletImage {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(width: 768, quality: 95, placeholder: NONE, layout: CONSTRAINED)
                        }
                    }
                }
                mobileImage {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(width: 320, quality: 95, placeholder: NONE, layout: CONSTRAINED)
                        }
                    }
                }
                link {
                    url
                    target
                }
            }
        }
        template {
            ... on WpTemplate_FeatureArticle {
              ...featuredTemplateQuery
                acfGoogleAdsSidebar {
                    sidebarMpus {
                        type
                        name
                    }
                }
                acfAdsBlocks {
                    rectangleLink {
                        target
                        url
                        title
                        }
                        rectangleImage {
                        altText
                        sourceUrl
                        localFile {
                            childImageSharp {
                            gatsbyImageData(width: 300, height:600, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                            }
                        }
                        }
                        squareLink {
                        target
                        url
                        title
                        }
                        squareImage {
                        altText
                        sourceUrl
                        localFile {
                            childImageSharp {
                            gatsbyImageData(width: 300, height:250, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                            }
                        }
                        }
                    }
            }
        }
    }
}
`

export default FeaturedTemplate