import React from "react"
import { Row, Col, Container } from "reactstrap"
import styled from "styled-components"
import { media } from "utils/Media"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons";
// import { FacebookShareButton, TwitterShareButton } from 'react-share';
import FlexibleContentFeatured from "components/FlexibleContent/FlexibleContentFeatured"
import Carousel from "components/FlexibleContent/Blocks/HeroCarousel/Carousel"
import Article from "components/shared/Article"
import SidebarBlock from "components/shared/SidebarBlock"
import ContainerMax from "components/shared/ContainerMax"
import SidebarAds from "components/shared/SidebarAds"
// import SidebarTempAds from "components/shared/SidebarTempAds"

const ContainerStyled = styled(Container)`
    padding-right: 0;
    padding-left: 0;

    @media ${media.md} {
        padding-right: 15px;
    }

    .content {
        @media ${media.lg} {
            width: 740px;
            max-width: calc(100% - 330px);
        }
    }

    .sidebar {
        width: 100%;
        @media ${media.lg} {
            max-width: 330px;
        }
    }
`

const FeaturedBlock = (props) => {
    const { data } = props
    // let link = ""
    // if (typeof window !== "undefined") link = window.location.href

    return(
        <>
            {data.template.acfFeatureArticle.slides !== null &&
                <ContainerMax maxWidth="1920" noPadding>
                    <Carousel slides={data.template.acfFeatureArticle.slides} />
                </ContainerMax>
            }
            {data.template?.acfTwoColumnFlexibleContent?.sidebar?.map((node, i) => {
                if (node.acfSidebarBlocks.sidebarBlockType[0].__typename === "WpSidebarBlock_Acfsidebarblocks_SidebarBlockType_Rolex") {
                    return(
                        <div className="d-lg-none">
                            <iframe src="https://static.rolex.com/clocks/2023/thechevronchampionship_mobile_HTML_320x90/rolex.html"
                                style={{
                                    width: "100%",
                                    height: "90px",
                                    border: 0,
                                    margin: 0,
                                    padding: 0,
                                    overflow: "hidden",
                                    scroll: "none"
                                }}
                                title="Rolex"
                                scrolling="no"
                                loading="lazy"
                                className="d-lg-none"
                            />
                        </div>
                    )
                }
                return null
            })}
            <ContainerStyled className="py-5">
                <Article>
                    <Row className="justify-content-lg-between">
                        <Col xs={12} lg="auto" className="content">
                            <Row>
                                {data.date &&
                                    <Col xs={12}>
                                        <div className="date-share clearfix">
                                            <p className="date">{data.date}</p>
                                            {/* <div className="share">
                                                <FacebookShareButton className="share-button" url={link}><FontAwesomeIcon icon={faFacebookF} /></FacebookShareButton>
                                                <TwitterShareButton className="share-button" url={link}><FontAwesomeIcon icon={faTwitter} /></TwitterShareButton>
                                            </div> */}
                                        </div>
                                    </Col>
                                }
                                <Col xs={12} className="d-flex flex-wrap">
                                    <FlexibleContentFeatured blocks={data.template?.acfTwoColumnFlexibleContent.twoColumnBlocks} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} lg="auto" className="sidebar">
                            <aside>
                                <div className="justify-content-center">
                                    <Col xs={{ size: 12, order: 2 }} md={{ order: 1 }} className="pb-3 pb-md-0 px-1 px-lg-0 d-flex justify-content-center flex-wrap">
                                        {/* <SidebarGoogleAds googleAdsSidebar={props.googleAdsSidebar} /> */}
                                        <SidebarAds data={data.template.acfAdsBlocks} googleAdsSidebar={props.googleAdsSidebar} />
                                        {/* <SidebarTempAds /> */}
                                    </Col>
                                    <Col xs={{ size: 12, order: 1}} md={{ order: 2 }}>
                                        <Row>
                                            {data.template?.acfTwoColumnFlexibleContent.sidebar && data.template.acfTwoColumnFlexibleContent.sidebar.map((node, i) => {
                                                switch (node.acfSidebarBlocks.sidebarBlockType[0].__typename) {
                                                    case "WpSidebarBlock_Acfsidebarblocks_SidebarBlockType_ImageLink":
                                                        return(
                                                            <Col xs={6} md={6} lg={12} className="pb-3 pb-md-0 px-lg-0" key={i}>
                                                                <SidebarBlock id={node.databaseId} margin={true} />
                                                            </Col>
                                                        )

                                                    case "WpSidebarBlock_Acfsidebarblocks_SidebarBlockType_Rolex":
                                                        return(
                                                            <Col xs={6} md={6} lg={12} className="d-none d-lg-block pb-3 pb-md-0 px-lg-0" key={i}>
                                                                <SidebarBlock id={node.databaseId} margin={true} />
                                                            </Col>
                                                        )
                                                
                                                    default:
                                                        return "";
                                                }
                                            })}
                                        </Row>
                                    </Col>
                                </div>
                            </aside>
                        </Col>
                    </Row>
                </Article>
            </ContainerStyled>
        </>
    )
}

export default FeaturedBlock